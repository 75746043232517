import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeadings"

import { Helmet } from "react-helmet"
import SEO from "../components/seo"

import ProductPageComponent from "../components/ProductPageComponent"

import styled from "styled-components"

import DiskPadAssistImage from "../images/DiskPadAssist.jpg"
import DiskTractionImage from "../images/DiskTraction.jpg"
import OrbitalImage from "../images/orbital-deck-400.png"

import DiskPadImageLarge from "../images/DeckPadAssistLarge.jpg"
import DiskTractionImageLarge from "../images/DiskTractionLarge.jpg"
import K10Compact17Image from "../images/k10-compact-17d-padassistx500.jpg"

const K10PageStyle = styled.main`
  padding: 2rem;
`

const K10CompactPage = () => {
  return (
    <>
      <SEO title="Floor Scrubbers: K10 Compact Walk-Behind Industrial Cleaning Equipment - Kodiak Cleaning Equipment" />
      <Helmet>
        <title>
          Floor Scrubbers: K10 Compact Walk-Behind Industrial Cleaning Equipment
          - Kodiak Cleaning Equipment
        </title>
        <description></description>
      </Helmet>
      <MainLayout>
        <PageHeading
          pageHeading="K10 Compact Floor Scrubber"

          // description="Here is the info about the K3 scrubbers and what they are used for"
        />
        <K10PageStyle>
          <ProductPageComponent
            descriptionTitle1="Solution Tank Capacity:"
            descriptionTitle2="Run Time:"
            chooseTitle="Choose Your Scrub Deck"
            mainDiskImage={K10Compact17Image}
            mainCylindricalImage={K10Compact17Image}
            mainOrbitalImage={K10Compact17Image}
            diskTitle="K10 Compact"
            subTitle="Walk-Behind Small Floor Scrubber"
            diskDescription="Cleaning small areas with a budget in mind has just gotten easier with the K11 Series of Floor Scrubbers.  Don’t let the size fool you, this floor scrubber provides premium performance and durability compared to others in the market.  Maintain cleanliness in Auto Shops, Sports Arenas, and Small Warehouses easily.  The squeegee blades and brushes can be rotated or flipped without tools to allow maximum usage prior to replacement and to aid in easy changes."
            diskCleaningPath="17 - 20in (43 - 51cm)"
            diskGallons="Sol: 10gal (38L) Rec: 10gal (38L)"
            diskRunTime=" Up to 2.5 hours"
            cylindricalTitle="K10 Compact"
            cylindricalDescription="Cleaning small areas with a budget in mind has just gotten easier with the K10 Series of Floor Scrubbers.  Don’t let the size fool you, this floor scrubber provides premium performance and durability compared to others in the market.  Maintain cleanliness in Auto Shops, Sports Arenas, and Small Warehouses easily.  The squeegee blades and brushes can be rotated or flipped without tools to allow maximum usage prior to replacement and to aid in easy changes."
            cylindricalCleaningPath="17 - 20in (43 - 51cm)"
            cylindricalGallons="Sol: 10gal (38L) Rec: 10gal (38L)"
            cylindricalRunTime=" Up to 2.5 hours"
            orbitalTitle="K11 Orbital"
            orbitalDescription="Cleaning small areas with a budget in mind has just gotten easier with the K11 Series of Floor Scrubbers.  Don’t let the size fool you, this floor scrubber provides premium performance and durability compared to others in the market.  Maintain cleanliness in Auto Shops, Sports Arenas, and Small Warehouses easily.  The squeegee blades and brushes can be rotated or flipped without tools to allow maximum usage prior to replacement and to aid in easy changes."
            orbitalCleaningPath="17 - 20in (43 - 51cm)"
            orbitalGallons="Sol: 11gal (42l) Rec: 12gal (45l)"
            orbitalRunTime=" Up to 2.5 hours"
            DeckImageThumb1={DiskPadAssistImage}
            DeckImageThumb2={DiskTractionImage}
            // DeckImageThumb3={OrbitalImageThumb}
            DeckImage1Description="Disk Pad Assist"
            DeckImage2Description="Disk Traction"
            // DeckImage3Description="Orbital"
            DeckImageLarge1={DiskPadImageLarge}
            DeckImageLarge2={DiskTractionImageLarge}
            DeckImageLarge3={OrbitalImage}
            deckTitle1="Why Disk-Traction?"
            deckDescription1="Lowest Maintenance Cost"
            deckDescription2="Brushes Individually Driven for Unmatched Performance"
            deckDescription3="Improved Cleaning of Irregular Floors"
            deckTitle2="Why Disk-Traction?"
            deckDescription4="Powered Reverse"
            deckDescription5="Ramp Climbing"
            deckDescription6="Operator Ease"
            // deckTitle="Why Orbital"
            deckDescription7="Chemical Free Stripping"
            deckDescription8="70% Reduction in Water Usage"
            deckDescription9="Floor Finish Prep & Recoat"
            brochurePdf="../k-k10s-k10c-BROCHURE-V20073.pdf"
            techPdf="../K10-Kodiak-Specs.pdf"
            partsPdf="../k-k10-c-p-en.pdf"
            operatorPdf="../k-k10-compact-op-en.pdf"
            displayHood="none"
            showPM="none"
            mtLink="https://www.dropbox.com/s/evrcuco63ko0478/daily-weekly-maintenance-3rd-v21306.pdf?dl=0"
            ></ProductPageComponent>
        </K10PageStyle>
      </MainLayout>
    </>
  )
}

export default K10CompactPage
